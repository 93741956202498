export const Routes = {
  Login: '/login',
  PasswordLogin: '/password-login',
  ForgotPassword: '/forgot-password',
  ForgotPasswordVerify: '/forgot-password-verify',
  Signup: '/signup',
  Verify: '/onboard/verify',
  Following: '/following',
  Trending: '/trending',
  VerifyComplete: '/onboard/verify/complete',
  UsernameFollowing: (username: string) => `/${username}/following`,
  UsernameFollowers: (username: string) => `/${username}/followers`,
  FollowUsers: '/follow',
  myAssetTrades: (symbol: string, type: string) => `/me/trades/${symbol}:${type}`,
  profile: (userUuid: string) => `/profile/${userUuid}`,
  usernameProfile: (username: string) => `/${username}`,
  PendingRequests: '/me/pending-requests',
  BlockedAccounts: '/blocked-accounts',
  LinkedAccount: '/linked-account/:accountNumber',
  LinkedAccounts: '/linked-accounts',
  brokerLinkComplete: (brokerId: string) => `/link-complete/${brokerId}`,
  LoadingPopup: '/loading-popup',
  OnboardChooseBroker: '/onboard/choose-broker',
  OnboardLinkAccount: '/onboard/link',
  onboardLinkComplete: (brokerId: string) => `/onboard/linking/${brokerId}`,
  OnboardProfile: '/onboard/profile',
  OnboardTags: '/onboard/tags',
  OnboardTwitter: '/onboard/twitter',
  OnboardTwitterFollow: '/onboard/twitter/follow',
  OnboardTopInvestors: '/onboard/top-investors',
  TwitterFollow: '/twitter/follow',
  Portfolio: '/portfolio',
  PrivacyAndSecurity: '/privacy-security',
  Account: '/account',
  Display: '/display',
  Discover: '/discover',
  search: (searchTerm: string) => `/search/${searchTerm}`,
  Notifications: '/notifications',
  tradeComplete: (orderId: number | string, symbol: string, intent: string) =>
    `/trade-complete/${orderId}/${symbol}/${intent}`,
  tradeDetail: (tradeId: string) => `/trade/${tradeId}`,
  tradeCommentDetail: (tradeId: string, commentId?: string) => `/trade/${tradeId}/comment/${commentId}`,
  tradeIntent: (intent: string, symbol: string, type: string) => `/trade-intent/${intent}/${symbol}:${type}`,
  tradePreview: (
    intent: string,
    symbol: string,
    type: string,
    quantity: number | string,
    price: number | string,
    accountId: number | string
  ) => `/trade-preview/${intent}/${symbol}:${type}/${quantity}:${price}/${accountId}`,
  userComments: (username: string) => `/${username}/comments`,
  userMemos: (username: string) => `/${username}/memos`,
  userPosts: (username: string) => `/${username}/posts`,
  userTrades: (username: string) => `/${username}/trades`,
  editMemo: (memoId?: string) => `/publish-memo${memoId ? `/${memoId}` : ''}`,
  memoDetail: (memoId?: string) => `/memo/${memoId}`,
  postDetail: (postId?: string) => `/post/${postId}`,
  memoCommentDetail: (memoId?: string, commentId?: string) => `/memo/${memoId}/comment/${commentId}`,
  ParamsMemo: '/params/memo',
  asset: (symbol: string, type: string) => `/asset/${symbol}:${type}`,
  assetMemos: (symbol: string, type: string) => `/asset/${symbol}:${type}/memos`,
  assetTrades: (symbol: string, type: string) => `/asset/${symbol}:${type}/trades`,
  assetNews: (symbol: string, type: string) => `/asset/${symbol}:${type}/news`,
  watchlist: (userUuid: string) => `/watchlist/${userUuid}`,
  taggedProfiles: (tag: string) => `/tagged-profiles/${tag}`,
  taggedContent: (tag: string) => `/tagged-content/${tag}`,
  Home: '/'
}

export enum ShareQueryActions {
  Invite = 'invite',
  Trade = 'trade',
  Memo = 'memo',
  Inviter = 'inviter',
  Tokens = 'tokens'
}

export const TermsLink = 'https://www.commonstock.com/terms/'
export const PrivacyPolicyLink = 'https://www.commonstock.com/privacy/'
export const CopyrightPolicyLink = 'https://www.commonstock.com/copyright/'

export const AirTable = 'https://airtable.com/shrKJSfbhbRJDYXOu'
